import { VASPIssuers } from "~/types/vaspid";

// eslint-disable-next-line no-shadow
export enum ViewerPermissions {
  "manage" = "manage",
  "address.uploadAddressList" = "address.uploadAddressList",
  "address.registerCustomer" = "address.registerCustomer",
  "auth.customerToken" = "auth.customerToken",
  "auth.addUserToVASP" = "auth.addUserToVASP",
  "auth.removeUserFromVASP" = "auth.removeUserFromVASP",
  "auth.vaspAdminsUserInfo" = "auth.vaspAdminsUserInfo",
  "auth.newCustomer" = "auth.newCustomer",
  "auth.getCustomer" = "auth.getCustomer",
  "auth.vaspUsers" = "auth.vaspUsers",
  "auth.manageAPIcredentials" = "auth.manageAPIcredentials",
  "integrations.manageApiCredentials" = "integrations.manageApiCredentials",
  "integrations.hasApiCredentials" = "integrations.hasApiCredentials",
  "integrations.useProvider" = "integrations.useProvider",
  "payment.subscriptionInfo" = "payment.subscriptionInfo",
  "payments.billing-customers.read" = "payments.billing-customers.read",
  "payments.subscriptions.create" = "payments.subscriptions.create",
  "payments.subscriptions.update" = "payments.subscriptions.update",
  "rules.get" = "rules.get",
  "rules.set" = "rules.set",
  "settings.updateSettings" = "settings.updateSettings",
  "settings.viewSettings" = "settings.viewSettings",
  "transactions.create" = "transactions.create",
  "transactions.list" = "transactions.list",
  "transactions.info" = "transactions.info",
  "transactions.approve" = "transactions.approve",
  "transactions.cancel" = "transactions.cancel",
  "transactions.update" = "transactions.update",
  "transactions.notify" = "transactions.notify",
  "transactions.accept" = "transactions.accept",
  "transactions.decline" = "transactions.decline",
  "transactions.confirm" = "transactions.confirm",
  "transactions.reject" = "transactions.reject",
  "transactions.redirect" = "transactions.redirect",
  "transactions.query" = "transactions.query",
  "transactions.export-requests.read" = "transactions.export-requests.read",
  "transactions.export-requests.create" = "transactions.export-requests.create",
  "transactions.autoDecryption" = "transactions.autoDecryption",
  "trustframework.listVASP" = "trustframework.listVASP",
  "trustframework.getVASP" = "trustframework.getVASP",
  "trustframework.updateVASP" = "trustframework.updateVASP",
  "trustframework.uploadVASPDocument" = "trustframework.uploadVASPDocument",
  "trustFramework.getVaspAnalytics" = "trustFramework.getVaspAnalytics",
  "api.vasps.activate" = "api.vasps.activate",
  "api.vasps.nest" = "api.vasps.nest",
  "trustframework.tfUpdate" = "trustframework.tfUpdate",
}

export type CompanyWizardState = {
  did?: string | null;
  name?: string | null;
  legalName?: string | null;
  website?: string | null;
  legalStructure?: string | null;
  yearFounded?: number | string | null;
  incorporationCountry?: string | null;
  isRegulated?: string | null;
  jurisdictions?: string | null;
  regulatoryStatus?: string | null;
  supervisoryAuthority?: string | null;
  registrationLicenseId?: string | null;
  additionalInformation?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  country?: string | null;
  identificationType?: string | null;
  businessNumber?: string | null;
  regulatoryAuthorities?: string | null;
  identificationCountry?: string | null;
  description?: string | null;
  logo?: string | null;
  onboardingIssuerData?: VASPIssuers;
  vaspnetId?: string;
  otherLegalName?: string | null;
  leiNumber?: string | null;
  postCode?: string | null;
  state?: string | null;
  number?: string | null;
  legalForm?: string | null;
  entityCategory?: string | null;
  entityStatus?: string | null;
  hqStreet?: string | null;
  hqNumber?: string | null;
  hqCountry?: string | null;
  hqPostcode?: string | null;
  hqRegion?: string | null;
  hqCity?: string | null;
};
